import { type IError } from '~/types/index'

export function usePageStoreFront() {
  // Get one event from DB, used on single event page
  const getOne = async (slug: string) => {
    try {
      const data = await useFetchAuth(`/api/v1/front/page/${slug}`, {
        method: 'POST',
      })
      return data
    } catch (e) {
      return e as IError
    }
  }

  const getAll = async (currentLocale: string) => {
    try {
      const pages = await useFetchAuth(`/api/v1/front/page/get-all`, {
        method: 'POST',
        body: { currentLocale: currentLocale },
      })
      return pages
    } catch (e) {
      return e as IError
    }
  }

  return {
    getOne,
    getAll,
  }
}
